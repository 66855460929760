import App from 'next/app'
import { appWithTranslation, withNamespaces } from '>/i18n'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/node'
import { Integrations } from '@sentry/tracing'
import moment from 'moment'
import get from 'lodash/get'

import Error from '>/pages/_error'
import withApolloClient from '~/hoc/withApolloClient'
import config, { DEFAULT_FESTIVAL } from '~/config'

import { ModalProvider } from '~/components/Modal/ModalContext'

import cssMain from '+/main.sass'
import cssFDW from '+/festival-fdw.sass'
import cssRW from '+/festival-rw.sass'
import cssRwx from '+/festival-rwx.sass'
import cssKORW from '+/festival-korw.sass'
import cssBFW from '+/festival-bfw.sass'
import cssDW from '+/festival-dw.sass'
import cssCF from '+/festival-cf.sass'

import {
  getStaticFilePath,
  hasWindow,
  supportsIntersectionObserver,
  getFestival,
  getFestivalRange,
  processEnv,
} from '~/utils/helpers'

const UA_KEY = 'UA-67017900-1'

moment.locale('pl')

Sentry.init({
  dsn: config().sentryDsn,
  enabled: !!config().sentryDsn,
  environment: config().sentryEnv,
  maxBreadcrumbs: 50,
  debug: false,
  release: config().sentryRelease,
  sendDefaultPii: true,
  beforeSend (event, hint) {
    const error = hint.originalException
    // Modify fingerprint for graphQL errors
    if (error && Object.getOwnPropertyNames(error).includes('graphQLErrors')) {
      event.fingerprint = [
        '{{ default }}',
        '{{ transaction }}',
        String(error.message)
      ]
    }
    return event
  },
  integrations: [new Integrations.BrowserTracing(
    {
      tracingOrigins: ['localhost', /.*\.rclub\.dev/, /.*\.restaurantclub\.pl/, /^\//]
    }
  )],
  tracesSampler: samplingContext => {
    switch (processEnv.NEXT_ENV) {
      case 'production':
        return 0.1
      default:
        return 1.0
    }
  }
})

@withNamespaces('meta')
class MyApp extends App {

  componentWillMount () {
    if (hasWindow() && !supportsIntersectionObserver()) require('intersection-observer')
  }

  componentDidMount () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/_next/static/service-worker.js')
    }

    this.handleRouteChange()
    this.props.router.events.on('routeChangeComplete', this.handleRouteChange)
  }

  componentWillUnmount () {
    this.props.router.events.off('routeChangeComplete', this.handleRouteChange)
  }

  handleRouteChange = async () => {
    const { router } = this.props

    hasWindow() && window.gtag && window.gtag('config', UA_KEY, {
      'page_path': router.asPath
    })
  }

  get festivalName () {
    const { router, domain } = this.props

    return getFestival(router, domain) || DEFAULT_FESTIVAL
  }

  get festival () {
    return config(this.festivalName).festival
  }

  get sentryTransaction () {
    const transaction = Sentry.getCurrentHub()
      .getScope()
      .getTransaction()

    if (transaction === undefined) {
      const transaction = Sentry.startTransaction({
        op: 'transaction',
        name: 'App'
      })
      Sentry.configureScope(scope => {
        scope.setSpan(transaction)
      })
    }

    return transaction
  }

  get sentryTraceId () {
    const transaction = this.sentryTransaction
    if (transaction) {
      return transaction.toTraceparent()
    }

    return ''
  }

  get styles () {
    switch (get(this.festival, 'id')) {
      case 'festival-fdw':
        return cssFDW
      case 'festival-rw':
        return cssRW
      case 'festival-rwx':
        return cssRwx
      case 'festival-korw':
        return cssKORW
      case 'festival-bfw':
        return cssBFW
      case 'festival-dw':
        return cssDW
      case 'festival-cf':
        return cssCF
      default:
        return cssMain
    }
  }

  get ogTagPhotos () {
    switch (get(this.festival, 'id')) {
      case 'festival-fdw':
        return 'https://finediningweek.pl/static/assets/image-hero.jpg'
      case 'festival-rw':
        return 'https://restaurantweek.pl/static/assets/RWP20J.png'
      case 'festival-bfw':
        return 'http://beerfoodweek.pl/static/assets/hero-bfw_02.jpg'
      case 'festival-rwx':
        return 'https://restaurantweek.pl/static/assets/hero-xmas.png'
      case 'festival-korw':
        return 'https://koneser.restaurantweek.pl/static/assets/hero-korw.jpg'
      case 'festival-dw':
        return 'https://restaurantweek.pl/static/assets/dw_share.png'
      case 'festival-cf':
        return 'https://cocktailfestival.pl/static/assets/cf_share.jpg'
      default:
        return 'https://restaurantweek.pl/static/assets/RWP20J.png'
    }
  }

  get description () {
    const { t, pageProps: { startsAt, endsAt, price } } = this.props
    const dateRange = getFestivalRange({ startsAt, endsAt })
    return price
      ? t(`app.${this.festivalName}.description`, { dateRange, price })
      : t(`app.${this.festivalName}.description_inactive`)
  }

  get pageTitle () {
    return this.props.t(`app.${this.festivalName}.title`)
  }

  getFonts () {
    const fonts = [
      { href: getStaticFilePath('fonts/SofiaPro-Regular.woff'), type: 'font/woff' },
      { href: getStaticFilePath('fonts/SofiaPro-Semibold.woff'), type: 'font/woff' },
    ]

    return (
      <>
        {this.festivalName === 'fdw' && <link
          href={getStaticFilePath('fonts/PlayfairDisplay-Regular.ttf')}
          type={'font/ttf'}
          rel='preload' as='font' crossOrigin='anonymous'
        />}
        {fonts.map(font => <link
          key={font.href}
          href={font.href}
          type={'font/woff'}
          rel='preload' as='font' crossOrigin='anonymous'
        />)}
      </>
    )
  }

  getFavicon () {
    switch (get(this.festival, 'id')) {
      case 'festival-bfw': return <link rel='shortcut icon' href={getStaticFilePath('favicon-bfw.ico')} />
      default: return <link rel='shortcut icon' href={getStaticFilePath('favicon.png')} />
    }
  }

  render () {
    const { Component, pageProps, apolloClient, apolloState = null } = this.props
    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props
    const modifiedPageProps = { ...pageProps, err }

    if (get(pageProps, 'err.statusCode') === 404) {
      return <Error statusCode={pageProps.err.statusCode} />
    }

    return (
      <>
        <Head>
          <title>{this.pageTitle}</title>
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
          <meta name='description' content={this.description} />
          <meta property='og:image' content={this.ogTagPhotos} key='image' />
          <meta property='og:title' content={this.pageTitle} key='title' />
          <meta property='og:description' content={this.description} key='description' />
          <meta property='og:type' content='website' />
          <meta property='og:site_name' content={this.pageTitle} />
          {this.getFavicon()}
          {this.getFonts()}
          <link rel='stylesheet' href={this.styles} />
          <meta name='apple-itunes-app' content='app-id=1490565530, affiliate-data=restaurantclub' />
          <meta name='facebook-domain-verification' content='h3qy4e8vlegdngh8aa9l4f60dewdj2' />
          <meta name='sentry-trace' content={this.sentryTraceId} />
          {/* Works on stg, demo and prod */}
          {/* Google Tag Manager */}
          {config().enableAnalytics
            && <script
              async
              dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${config().gtm_key}');
                `
              }}
            />
          }
          {/* Google Tag Manager (noscript) */}
          {config().enableAnalytics && <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${config().gtm_key}`}
              height='0'
              width='0'
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>}
          <script
            defer
            dangerouslySetInnerHTML={{
              __html: `window.__APOLLO_STATE__=${JSON.stringify(apolloState).replace(/</g, '\\u003c')};`,
            }}
          />
        </Head>
        <ApolloProvider client={apolloClient}>
          <ModalProvider>
            <Component {...modifiedPageProps} />
          </ModalProvider>
        </ApolloProvider>
      </>
    )
  }
}

export default withApolloClient(appWithTranslation(MyApp))
